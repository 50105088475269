import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";
import SEO from "../../seo";
export default function PerformanceArts() {
  return (
    <Layout>
      <SEO
        title="Performance Arts | Chinmaya Vidyalaya"
        description="Students are encouraged to explore the plethora of artistic activities and creative pursuits on offer – including Indian classical music, dance and theatre performances."
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="performancearts">
        <h1 className="heading">Performance Arts</h1>

        <p className="performancearts__text">
          At Chinmaya Vidyalaya, students are encouraged to explore the plethora
          of artistic activities on offer. Whether it be percussion instruments,
          Indian classical music and vocals, dance performance or theatre
          performances, the children are exposed to a variety of creative
          pursuits for regular practice.
          <br />
          <br />
          The students utilizing the numerous musical instruments on offer is
          music to our ears!
        </p>

        <div className="performancearts__images">
          <div className="row">
            {data.map((item) => (
              <div className="col col-12">
                <img className="performancearts__images__img" src={item}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
