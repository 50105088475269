const data = [

  "https://www.chinmayavvdelhi.ac.in/images/Blackele/banner_Prize_Distribution_Ceremony.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/banner_swamiji_visit_to_vidyalaya.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Music_and_Dance_3.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Music_and_dance_5.png",
  "https://chinmayavvdelhi.ac.in/assets/images/performance-art-2022.jpg",
];

export default data.reverse();
